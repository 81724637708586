import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from '../types/company.types';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  public getCompanies() {
    return this.http.get<Company[]>(environment.apiUrl + '/companies/');
  }

  public getCompany(companyID: number) {
    return this.http.get<Company>(environment.apiUrl + '/companies/' + companyID + '/');
  }

  public createCompany(company: Partial<Company>) {
    return this.http.post<Company>(environment.apiUrl + '/companies/', company).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessages = Object.keys(error.error)
          .map(key => `${key}: ${error.error[key].join(' ')}`) // Include the key in the message
          .join(' | '); // Separate errors with a delimiter
        return throwError(() => new Error(errorMessages || 'Something went wrong'));
      })
    );
  }

  public updateCompany(companyID: number, company: Partial<Company>) {
    return this.http.patch<Company>(environment.apiUrl + '/companies/' + companyID + '/', company).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessages = Object.keys(error.error)
          .map(key => `${key}: ${error.error[key].join(' ')}`) // Include the key in the message
          .join(' | '); // Separate errors with a delimiter
        return throwError(() => new Error(errorMessages || 'Something went wrong'));
      })
    );
  }

  // Method to delete a company by ID
  public deleteCompany(companyID: number): Observable<void> {
    return this.http.delete<void>(environment.apiUrl + '/companies/' + companyID + '/');
  }
}
