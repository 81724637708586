import { createReducer, createSelector, on } from '@ngrx/store';
import * as CompanyActions from '../actions/companies.actions';
import { Company } from 'src/app/shared/types/company.types';

export const initialCompaniesState: Company[] = null;
export const initialCompanyState: Company = null;

export const companiesReducer = createReducer(
  initialCompaniesState,
  on(CompanyActions.loadCompaniesSuccess, (state, { companies }) => companies),
  on(CompanyActions.createCompanySuccess, (state, { company }) => [company, ...state]),
  on(CompanyActions.deleteCompanySuccess, (state, { companyID }) => {
    // Filter out the deleted company from the state
    return state.filter(company => company.id !== companyID);
  })
);

export const companyReducer = createReducer(
  initialCompanyState,
  on(CompanyActions.loadCompanySuccess, (state, { company }) => company),
  on(CompanyActions.updateCompanySuccess, (state, { company }) => ({
    ...company,
  })),
  on(CompanyActions.CompanySetInitial, () => initialCompanyState)
);
