import * as LeadTypesActions from '../actions/lead-types.actions';
import { LEAD_TYPES_LOAD_SUCCESS } from '../actions/lead-types.actions';
import { LeadType } from 'src/app/shared/types/lead-types.types';

const initialState: LeadType[] = null;

export function reducer(state = initialState, action: LeadTypesActions.LeadTypesActions): LeadType[] {
  switch (action.type) {
    case LEAD_TYPES_LOAD_SUCCESS:
      return action.statuses;
    default:
      return state;
  }
}
