import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { CoachesLoadError, CoachesLoadSuccess, COACHES_LOAD_ERROR, COACHES_LOAD_SUCCESS } from '../actions/coaches.actions';

@Injectable()
export class CoachesEffects {
  public coachesLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CoachesLoadSuccess>(COACHES_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public coachesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CoachesLoadError>(COACHES_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Coaches load failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
}
