import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotificationService } from '../../shared/services/notification.service';
import { LeadService } from 'src/app/shared/services/lead.service';
import {
  LeadTypesLoad,
  LeadTypesLoadSuccess,
  LeadTypesLoadError,
  LEAD_TYPES_LOAD,
  LEAD_TYPES_LOAD_SUCCESS,
  LEAD_TYPES_LOAD_ERROR,
} from '../actions/lead-types.actions';

@Injectable()
export class LeadTypesEffects {
  public leadTypesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LeadTypesLoad>(LEAD_TYPES_LOAD),
      mergeMap(() =>
        this.leadService.getLeadTypes().pipe(
          map(statuses => {
            return new LeadTypesLoadSuccess(statuses);
          }),
          catchError(() => of(new LeadTypesLoadError()))
        )
      )
    )
  );

  public leadTypesLoadSuccess$ = createEffect(() => this.actions$.pipe(ofType<LeadTypesLoadSuccess>(LEAD_TYPES_LOAD_SUCCESS)), {
    dispatch: false,
  });

  public leadTypesLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LeadTypesLoadError>(LEAD_TYPES_LOAD_ERROR),
        tap(() => this.notificationService.openSnackBar('error', 'Pre Statuses not loaded'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService, private leadService: LeadService) {}
}
