import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { LeadsLoadError, LeadsLoadSuccess, LEADS_LOAD_ERROR, LEADS_LOAD_SUCCESS } from '../actions/leads.actions';

@Injectable()
export class LeadsEffects {
  public leadsLoadSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LeadsLoadSuccess>(LEADS_LOAD_SUCCESS),
        tap(payload => {
          this.notificationService.loading = false;
        })
      ),
    { dispatch: false }
  );

  public leadsLoadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LeadsLoadError>(LEADS_LOAD_ERROR),
        tap(() => (this.notificationService.loading = false)),
        tap(() => this.notificationService.openSnackBar('error', 'Leads load failed'))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
}
