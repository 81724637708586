import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { LayoutLoginComponent } from './components/layout-login/layout-login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { AppComponent } from './containers/app/app.component';
import { AnswersEffects } from './effects/answer.effects';
import { AnswerTopicsEffects } from './effects/answer-topic.effects';
import { CityEffects } from './effects/cities.effects';
import { CoachEffects } from './effects/coach.effects';
import { CoachesEffects } from './effects/coaches.effects';
import { ConversationEffects } from './effects/conversation.effects';
import { CountryEffects } from './effects/countries.effects';
import { EmailLogsEffects } from './effects/email-log.effects';
import { EnvironmentEffects } from './effects/environments.effects';
import { FeedbackStatusEffects } from './effects/feedback-status.effects';
import { FeedbacksEffects } from './effects/feedbacks.effects';
import { FieldEffects } from './effects/fields.effects';
import { GenderEffects } from './effects/gender.effects';
import { LanguageEffects } from './effects/languages.effects';
import { MenteeRelationsEffects } from './effects/mentee-relations.effects';
import { MenteeEffects } from './effects/mentee.effects';
import { MenteesEffects } from './effects/mentees.effects';
import { MentorEffects } from './effects/mentor.effects';
import { MentorsEffects } from './effects/mentors.effects';
import { MrStatusEffects } from './effects/mr-status.effects';
import { PersonalAttributeEffects } from './effects/personal-attributes.effects';
import { ProgrammeEffects } from './effects/programmes.effects';
import { PromoCodeEffects } from './effects/promo-codes.effects';
import { SkillEffects } from './effects/skills.effects';
import { StatusEffects } from './effects/statuses.effects';
import { SubmissionsEffects } from './effects/submission.effects';
import { TeamSizeEffects } from './effects/team-sizes.effects';
import { UserEffects } from './effects/user.effects';
import { LeadEffects } from './effects/lead.effects';
import { LeadsEffects } from './effects/leads.effects';
import { LeadStatusEffects } from './effects/lead-statuses.effects';
import { ReminderEffects } from './effects/reminders.effects';
import { CompanyEffects } from './effects/companies.effects';
import { LeadTypesEffects } from './effects/lead-types.effects';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    RouterModule,
    EffectsModule.forFeature([SubmissionsEffects]),
    EffectsModule.forFeature([MenteeEffects]),
    EffectsModule.forFeature([MenteesEffects]),
    EffectsModule.forFeature([CoachesEffects]),
    EffectsModule.forFeature([CoachEffects]),
    EffectsModule.forFeature([MentorEffects]),
    EffectsModule.forFeature([MentorsEffects]),
    EffectsModule.forFeature([MenteeRelationsEffects]),
    EffectsModule.forFeature([FeedbacksEffects]),
    EffectsModule.forFeature([AnswersEffects]),
    EffectsModule.forFeature([AnswerTopicsEffects]),
    EffectsModule.forFeature([EmailLogsEffects]),
    EffectsModule.forFeature([ConversationEffects]),
    EffectsModule.forFeature([UserEffects]),
    EffectsModule.forFeature([LanguageEffects]),
    EffectsModule.forFeature([EnvironmentEffects]),
    EffectsModule.forFeature([ProgrammeEffects]),
    EffectsModule.forFeature([CountryEffects]),
    EffectsModule.forFeature([CityEffects]),
    EffectsModule.forFeature([StatusEffects]),
    EffectsModule.forFeature([LeadEffects]),
    EffectsModule.forFeature([LeadsEffects]),
    EffectsModule.forFeature([LeadStatusEffects]),
    EffectsModule.forFeature([FeedbackStatusEffects]),
    EffectsModule.forFeature([PromoCodeEffects]),
    EffectsModule.forFeature([TeamSizeEffects]),
    EffectsModule.forFeature([GenderEffects]),
    EffectsModule.forFeature([FieldEffects]),
    EffectsModule.forFeature([SkillEffects]),
    EffectsModule.forFeature([GenderEffects]),
    EffectsModule.forFeature([MrStatusEffects]),
    EffectsModule.forFeature([PersonalAttributeEffects]),
    EffectsModule.forFeature([ReminderEffects]),
    EffectsModule.forFeature([CompanyEffects]),
    EffectsModule.forFeature([LeadTypesEffects]),
  ],
  declarations: [AppComponent, HeaderComponent, LayoutComponent, LeftMenuComponent, LayoutLoginComponent],
  exports: [AppComponent],
})
export class CoreModule {}
