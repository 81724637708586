import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CompanyActions from '../actions/companies.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CompanyService } from 'src/app/shared/services/company.services';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private notificationService: NotificationService
  ) {}

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompanies),
      mergeMap(() =>
        this.companyService.getCompanies().pipe(
          map(companies => CompanyActions.loadCompaniesSuccess({ companies })),
          catchError(error => of(CompanyActions.loadCompaniesFailure({ error })))
        )
      )
    )
  );

  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.loadCompany),
      mergeMap(action =>
        this.companyService.getCompany(action.companyID).pipe(
          map(company => CompanyActions.loadCompanySuccess({ company })),
          catchError(error => of(CompanyActions.loadCompanyFailure({ error })))
        )
      )
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.createCompany),
      mergeMap(action =>
        this.companyService.createCompany(action.company).pipe(
          map(company => {
            this.notificationService.openSnackBar('success', 'Company saved');
            return CompanyActions.createCompanySuccess({ company });
          }),
          catchError(error => {
            this.notificationService.openSnackBar('error', error.message || 'Creation failed');
            return of(CompanyActions.createCompanyFailure({ error }));
          })
        )
      )
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.updateCompany),
      mergeMap(action =>
        this.companyService.updateCompany(action.companyID, action.company).pipe(
          map(company => {
            this.notificationService.openSnackBar('success', 'Company saved');
            return CompanyActions.updateCompanySuccess({ company });
          }),
          catchError(error => {
            this.notificationService.openSnackBar('error', error.message || 'Update failed');
            return of(CompanyActions.loadCompanyFailure({ error: error.message || 'Update failed' }));
          })
        )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.deleteCompany),
      switchMap(action =>
        this.companyService.deleteCompany(action.companyID).pipe(
          map(() => CompanyActions.deleteCompanySuccess({ companyID: action.companyID })),
          catchError(error => of(CompanyActions.deleteCompanyFailure({ error })))
        )
      )
    )
  );
}
